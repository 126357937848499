import { getFirstAvailableStep } from '../components/ParticipantPage/Widget/views/utils';
import {
  Section,
  SectionWithSteps,
  Description,
  ParticipantSection,
  ParticipantStep,
} from '../types/v3Types';
import type { Section as SectionV3 } from '@wix/ambassador-online-programs-v3-section/types';

export const isSectionLocked = (section: ParticipantSection): boolean => {
  return !!getWaitingDate(section);
};

export const getFirstAvailableStepFromSection = (
  sections: ParticipantSection[] = [],
): ParticipantStep => {
  let step: ParticipantStep;

  sections.forEach((section) => {
    if (step || isSectionLocked(section)) {
      return;
    }

    step = getFirstAvailableStep(section.steps);
  });

  return step;
};

export function getFirstAvailableSection(sections: ParticipantSection[] = []) {
  return sections.find((section) => {
    return !isSectionLocked(section) && !sectionCannotBeOpenedInView(section);
  });
}

export function getFirstSection(sections: ParticipantSection[] = []) {
  return getFirstAvailableSection(sections) || sections?.[0];
}

export const getSectionDescription = (section: Section): Description => {
  return section?.description;
};

export const getSectionSteps = (section: SectionWithSteps) => {
  if (!section.steps) {
    console.warn('Section has no steps', section);
  }
  return section.steps;
};

export const getSectionTitle = (section: Section) => {
  return getSectionDescription(section)?.title;
};

export const getSectionDetails = (section: Section) => {
  return getSectionDescription(section)?.details;
};

export const getSectionMedia = (section: Section) => {
  const { image, video } = (section as SectionV3).description;
  if (image || video) {
    return { image, video };
  }
  return;
};

export const setDescription = (section: Section, description: Description) => {
  (section as SectionV3).description.details = description.details;
};

export const getWaitingDate = (section: ParticipantSection) => {
  return section?.waitingDate;
};

export const sectionCannotBeOpenedInView = (section: ParticipantSection) => {
  return !section || !section.id || section.withoutDetails;
};

export const getWaitingStart = (section: ParticipantSection) => {
  return getWaitingDate(section);
};
